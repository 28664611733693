export default {
    headline: {
        type: "headline",
        title: "",
        subtitle: ""
    },
    sectionTitle: {
        type: "sectionTitle",
        title: ""
    },
    textblock: {
        type: "textblock",
        text: ""
    },
    templateText: {
        type: "templateText",
        text: "",
        replaceKeyValue: []
    },
    infoBox: {
        type: "infoBox",
        text: "",
        color: "blue"
    },
    image: {
        fileName: '',
        type: "image",
        url: "",
        alt: "",
        hasShadow: true
    },
    code: {
        type: "code",
        text: ""
    },
    table: {
        type: "table",
        header: [],
        data: []
    },
    video: {
        fileName: '',
        type: "video",
        url: "",
        alt: "",
        hasShadow: true
    }
}