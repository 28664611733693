<script>
    import { link } from "svelte-routing";
    import config from "config";

    export let title = "Enable the jacando X REST API";
    export let caption = "Learn how to enable and manage the jacando X REST API and integrate your system.";
</script>

<style lang="scss">
    @import "hero";
</style>

<div class="hero">
    <div>
        <h6>{title}</h6>
        <p>{caption}</p>
        <a href="{config.slugs.guides + 'enabling-the-rest-api'}" use:link>Learn more
            <ion-icon name="ios-arrow-round-forward"></ion-icon>
        </a>
    </div>

    <img src="/assets/images/hero/hero.png" alt="hero"/>
</div>