<script>
    import Button from "components/UI/button/button.svelte";

    export let code = "";
    export let title = "";

    let html;

    // If the button was clicked
    let wasClicked = false;

    // The label which is passed to the button
    let copyLabel = "Copy";

    $: html = window.hljs.highlight("JSON", code).value;

    // When the copy button is clicked
    function copyExampleCode() {
        // Writing the code into the clipboard
        navigator.clipboard.writeText(code);

        // Adjusting the variables which are passed to the copy button
        wasClicked = true;
        copyLabel = "Copied!";

        // After 400ms we reset the button to default again
        setTimeout(function () {
            wasClicked = false;
            copyLabel = "Copy";
        }, 800);
    }
</script>


<style>
    div {
        display: flex;
        justify-content: space-between;
    }
</style>


<div><h4>{title}</h4>
    <Button label="{copyLabel}" onClick="{copyExampleCode}" wasClicked="{wasClicked}"/>
</div>

<pre>
    <code>
        {@html html}
    </code>
</pre>