<script>
    export let selectedElement;

    // Only show add row button when there is something in the header
    $: showAddRowButton = selectedElement.header.length !== 0;

    // Adds a new cell in the header and push in every row an empty string for the new header
    const addNewHeader = (index = selectedElement.header.length) => {
        let headerArrayCopy = [...selectedElement.header];

        if (headerArrayCopy.length === 4) {
            return;
        }

        headerArrayCopy.splice(index, 0, "");

        selectedElement.header = headerArrayCopy;

        selectedElement.data = selectedElement.data.map(tableRow => {
            let tableRowArrayCopy = [...tableRow];
            tableRowArrayCopy.splice(index, 0, "");
            return tableRowArrayCopy;
        });
    };

    // Adds a new row to the table (the amount of cells is dependent on the headers array length)
    const addNewRow = () => {
        const newRow = new Array(selectedElement.header.length).fill("");

        selectedElement.data = [...selectedElement.data, newRow];
    };

    // Removes a complete row from the table
    const removeRow = (index) => {
        let tableRowArrayCopy = [...selectedElement.data];

        tableRowArrayCopy.splice(index, 1);

        selectedElement.data = tableRowArrayCopy;
    };

    // Removes the header and every row cell to this header from the table
    const removeHeader = (index) => {
        let headerArrayCopy = [...selectedElement.header];

        headerArrayCopy.splice(index, 1);

        selectedElement.header = headerArrayCopy;

        selectedElement.data = selectedElement.data.map(tableRow => {
            let tableRowArrayCopy = [...tableRow];
            tableRowArrayCopy.splice(index, 1);
            return tableRowArrayCopy;
        });
    }

</script>

<style lang="scss">
    @import "table";
</style>

<div>
    <h5>Table</h5>
    <table>
        <thead>
        <tr>
            <th></th>
            {#each selectedElement.header as header, i}
                <th>
                    <button on:click={() => removeHeader(i)}>-</button>
                </th>
            {/each}
        </tr>
        <tr>
            <th></th>
            {#each selectedElement.header as header, i}
                <th>
                    <button on:click={() => addNewHeader(i)}>+</button>
                    <input placeholder="Header" bind:value={header}/>
                </th>
            {/each}
            <th>
                <button on:click={() => addNewHeader()}>+</button>
            </th>
        </tr>
        </thead>
        <tbody>
        {#each selectedElement.data as values, i}
            <tr>
                <td>
                    <button on:click={() => removeRow(i)}>-</button>
                </td>
                {#each values as value}
                    <td>
                        <input placeholder="Row Value" bind:value={value}/>
                    </td>
                {/each}
            </tr>
        {/each}
        <tr>
            <button hidden={!showAddRowButton} on:click={addNewRow}>+</button>
        </tr>
        </tbody>
    </table>
</div>