<script>
    import { fly } from 'svelte/transition';

    import SectionTitle from 'components/UI/sectionTitle/sectionTitle.svelte';
    import TextBlock from 'components/UI/textBlock/textBlock.svelte';
    import Header from 'components/UI/header/header.svelte';
    import InfoBox from 'components/UI/infoBox/infoBox.svelte';
    import Table from 'components/UI/table/table.svelte';
    import ImageLoader from 'components/UI/imageLoader/imageLoader.svelte';
    import NotFound from 'components/notFound/notFound.svelte';
    import TemplateText from 'components/UI/templateText/templateText.svelte';
    import VideoLoader from 'components/UI/videoLoader/videoLoader.svelte';

    import { global } from 'store';

    // Current path
    export let name = '';
    export let type = 'guides';

    let elements;
    let articles;

    $: articles = type === 'guides' ? $global.guides : $global.codeExamples;
    $: elements = articles[name] && articles[name].elements;

    // Transitions
    const t1 = { x: 70, duration: 200, delay: 150 };
    const t2 = { x: 70, duration: 200 };

</script>

<style lang="scss">
  @import "guide";
</style>

{#if !elements}

    <NotFound/>

{:else}

    <article in:fly={t1} out:fly={t2}>

        {#each elements as element}
            {#if element.type === "headline"}
                <Header title={element.title} subtitle={element.subtitle}/>
            {:else if element.type === "sectionTitle"}
                <SectionTitle title={element.title}/>
            {:else if element.type === "textblock"}
                <TextBlock text={element.text}/>
            {:else if element.type === "templateText"}
                <TemplateText text={element.text} replaceKeyValue={element.replaceKeyValue}/>
            {:else if element.type === "infoBox"}
                <InfoBox color={element.color} text={element.text}/>
            {:else if element.type === "image"}
                <ImageLoader url={element.url} alt={element.alt} hasShadow/>
            {:else if element.type === "code"}
                <pre>
                    <code>{element.text}</code>
                </pre>
            {:else if element.type === "table"}
                <Table header={element.header} data={element.data}/>
            {:else if element.type === "video"}
                <VideoLoader url={element.url} alt={element.alt} hasShadow/>
            {/if}
        {/each}

    </article>

{/if}
