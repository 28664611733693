<script>
    export let color = "blue";
    export let text = "";
</script>

<style lang="scss">
    @import "styling/stylesheet";

    .infoBox {
        width: 100%;
        display: flex;
        margin: 2.5rem 0;
        padding: 1.5rem 2.5rem;
        line-height: 2rem;
        border-radius: $border-radius;
        font-size: $small;
        font-weight: 500;
        color: $text-middle;
    }

    .blue {
        @extend .infoBox;
        border-left: 4px solid $blue;
        background-color: $bright-blue;
    }

    .yellow {
        @extend .infoBox;
        border-left: 4px solid $yellow;
        background-color: $yellow-light;
    }

    .green {
        @extend .infoBox;
        border-left: 4px solid $green;
        background-color: $green-light;
    }

    .grey {
        @extend .infoBox;
        border-left: 4px solid $light-grey;
        background-color: $grey-medium;
    }

    .red {
        @extend .infoBox;
        border-left: 4px solid $red;
        background-color: $red-light;
    }

    .purple {
        @extend .infoBox;
        border-left: 4px solid $purple;
        background-color: $purple-bright;
    }

    .orange {
        @extend .infoBox;
        border-left: 4px solid $orange;
        background-color: $orange-light;
    }
</style>


<div class="{color}">
    {text}
</div>