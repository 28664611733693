import { writable } from "svelte/store";

/**
 * The global storage is only manipulated in app.svelte (in the onMount and/or login)
 * @type {Writable<{cluster: string, guides: {}, namespace: string, originalPath: string, apiReference: []}>}
 */
export const global = writable({
    originalPath: "",
    cluster: "",
    namespace: "",
    apiReference: [],
    guides: {},
    codeExamples: {}
});