<script>
    import { fly } from "svelte/transition";
    import { navigate } from "svelte-routing";

    export let isMainMenu = false;
    export let isHashBased = false;
    export let data = [];
    export let prefix = "";

    let currentPath = window.location.pathname.split("/").pop();

    /**
     * Navigates to the selected menu item. If the menu
     * item is a hash value it scrolls the element
     * into the view port.
     * @param path
     */
    const navigateTo = (path) => {
        if (isHashBased) {
            const el = document.getElementById(path.substring(1));
            el && el.scrollIntoView({ behavior: "smooth" });
        }

        const targetDestination = (path !== "/") ? prefix + path : path;

        currentPath = path;

        navigate(targetDestination, { replace: true });
    };

    // Transitions
    const transitionIn = { x: isMainMenu ? -200 : 200, duration: 250, delay: 50, opacity: 20 };
    const transitionOut = { x: isMainMenu ? -200 : 200, duration: 250, opacity: 20 };
</script>


<style lang="scss">
    @import "menu";

    .active {
        color: $primary;
    }
</style>


<div class="menu" in:fly="{transitionIn}" out:fly="{transitionOut}">

    <div class="home" hidden="{isMainMenu}" on:click={() => navigateTo("/")}>
        <ion-icon name="ios-arrow-round-back"></ion-icon>
        <span>Home</span>
    </div>

    <ul class:isMainMenu>
        <li>
            <ion-icon name="ios-home"></ion-icon>
            <span>Home</span>
        </li>

        {#each data as item (item.label)}
            <li on:click="{() => navigateTo(item.path)}" class:active={currentPath === item.path && !isHashBased}>
                {#if item.icon}
                    <ion-icon name="{item.icon}"></ion-icon>
                {/if}

                <span>{item.label}</span>
            </li>
        {/each}
    </ul>

</div>