<script>
    export let title = "";
</script>

<style>
    h6 {
        margin-top: 5rem;
        margin-bottom: 1.5rem;
    }
</style>

<h6>{title}</h6>