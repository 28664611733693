<script>
    // Libs
    import { Route, Router } from 'svelte-routing';
    import { onMount } from 'svelte';
    import 'smoothscroll-polyfill';

    // Components
    import Sidebar from 'components/layout/sidebar/sidebar.svelte';
    import Guide from 'components/guide/guide.svelte';
    import Shell from 'components/layout/shell/shell.svelte';
    import Home from 'components/home/home.svelte';
    import API from 'components/api/api.svelte';
    import Login from 'components/login/login.svelte'
    import NotFound from 'components/notFound/notFound.svelte';
    import Editor from 'components/editor/editor.svelte';
    import PreviewBanner from 'components/UI/previewBanner/previewBanner.svelte';

    // Storage
    import { global } from 'store'

    // Helpers
    import config from 'config';
    import utils from 'utils';

    let password = '';
    let errorMessage = '';
    let localStorageData;
    let isLoading;

    /**
     * Sends the request to get the documentation data
     * @param accessToken {string} the password from the localstorage ("at")
     * @param isPreview {boolean} if the preview content should be returned
     * @returns {Promise<void>}
     */
    async function fetchData(accessToken = password, isPreview = false) {
        try {
            isLoading = true;

            let requestURL = config.connection.useProd ? config.connection.prodURL : config.connection.devURL;

            if (isPreview) {
                requestURL += '?mode=preview';
            }

            // Sending a GET request, providing password in the header
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                }
            });

            // If the response itself is undefined
            if (!response) {
                throw new Error('Something went wrong, please try again.');
            }

            // Parsing the response from the DB
            let docData = await response.json();

            // If we got an error response or the docData is undefined
            if (!docData || 'error' in docData) {
                localStorageData = null;
                localStorage.clear();

                throw new Error((docData && docData.error) || 'Something went wrong, please try again.');
            }

            // Updating the localStorage with data (documentation page object data) and at (login password)
            localStorage.setItem('data', JSON.stringify(docData));
            localStorage.setItem('at', accessToken);

            // Updating our storage with the newly received data, here we split them between api and guides
            global.update(prevStore => ({
                ...prevStore,
                apiReference: docData.apiReference,
                guides: docData.guides,
                codeExamples: docData.codeExamples
            }));

            // Updating the documentation data variable with the parsed response
            localStorageData = docData;

            isLoading = false;

        } catch (error) {
            errorMessage = error.message;

            isLoading = false;
        }
    }

    /**
     * Called when the button in the login is clicked.
     * It simply calls the fetchData function directly.
     */
    function handleSubmit() {
        fetchData();
    }

    /**
     * When the page mounts up, it reads certain params from the URL (if provided).
     * Possible params are cluster, namespace and accessToken (password).
     * Whereas the cluster and namespace are put in the global storage, the accessToken is put in the local storage.
     * Once the params are read from the URL, we remove them from the URL.
     * We check, if we got an accessToken from the URL or if we already have it in the localStorage, if so
     * we call the fetchData function which fetches the newest documentation data.
     * At the end, we update our global storage with all the data we could get to that point.
     * If for example we didn't have the accessToken and there was no data in the localStorage, it would mean, that
     * the variable localStorageData is empty, which leads the user directly to the login. Only params like
     * cluster and namespace could've been updated while data and accessToken is empty, it depends on if
     * they (cluster | namespace) was provided over the URL.
     */
    onMount(() => {
        const { search: params, pathname } = window.location;

        // Gets the cluster and namespace out of the url if it is provided
        const cluster = utils.getCluster(params);
        const namespace = utils.getNamespace(params);
        const isPreview = utils.isPreview(params);
        let accessToken = utils.getAccessToken(params);

        // Read data from local storage
        localStorageData = JSON.parse(localStorage.getItem('data'));
        accessToken = localStorage.getItem('at') || accessToken;

        // After reading the params, we remove them from the url
        window.history.replaceState({}, document.title, pathname);

        // If we got the accessToken from URL or localStorage, we send a new request
        if (accessToken) {
            fetchData(accessToken, isPreview);
        }

        if (!accessToken) {
            localStorageData = null;
            localStorage.clear();
        }

        // Updating the global state, at this point it can be, that most of the data is not provided
        global.set({
            isPreview,
            originalPath: pathname,
            namespace: namespace || '',
            cluster: cluster || '',
            apiReference: localStorageData && localStorageData.apiReference || [],
            guides: localStorageData && localStorageData.guides || {},
            codeExamples: localStorageData && localStorageData.codeExamples || {}
        });
    });

</script>

{#if !localStorageData}

    <Login bind:password bind:errorMessage bind:isLoading on:submit={handleSubmit} />

{:else}

    <Shell>
        <Sidebar />

        <main>
            <Router>

                <Route path="/">
                    <Home />
                </Route>

                <Route path="{config.slugs.api}">
                    <API />
                </Route>

                <Route path="{config.slugs.guides + ':name'}" let:params>
                    <Guide name="{params.name}" />
                </Route>

                <Route path="{config.slugs.codeExamples + ':name'}" let:params>
                    <Guide name="{params.name}" type="codeExample" />
                </Route>

                <Route path="/editor">
                    <Editor />
                </Route>

                <Route>
                    <NotFound />
                </Route>

            </Router>
        </main>
    </Shell>

    <PreviewBanner />

{/if}
