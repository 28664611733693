<script>
    export let selectedElement;
</script>

<style lang="scss">
    @import "code";
</style>

<div>
    <h5>Code</h5>
    <textarea
        bind:value={selectedElement.text}
        placeholder="Code"
    ></textarea>
</div>