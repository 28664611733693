<script>
    import { link } from "svelte-routing";
    import configs from "config";

    export let latestGuides;
</script>

<style lang="scss">
    @import "latestGuides";
</style>


<section>
    {#each latestGuides as {name, isNew, lastEdit, elements, url}}
        <a class="card" href="{configs.slugs.guides +  url}" use:link>
            <div class="tag">
                <div>{(isNew) ? "New" : "Updated" }</div>
            </div>

            <h6>{name}</h6>
            <p class="lastEdit">{(isNew) ? "Added" : "Updated"}: {lastEdit}</p>
            <p class="description">{elements.find(x => x.type === "headline").subtitle}</p>
        </a>
    {/each}
</section>
