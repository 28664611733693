<script>
    export let header = [];
    export let data = [];
</script>

<style lang="scss">
    @import "styling/stylesheet";

    table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;
        font-size: $smaller;
    }

    tr {
        height: 4.5rem;

        &:nth-child(n + 2) {
            min-height: 5.5rem;
            border-top: 1px solid $light-grey;
            font-size: $small;
        }
    }

    th,
    td {
        width: 100%;
        padding: 0 0 0 1.5rem;
        text-align: left;
    }

    th:first-of-type,
    td:first-of-type {
        width: auto;
        padding: 0 2.5rem 0 0.5rem;
        text-align: right;
        white-space: nowrap;
    }


    .value {
        color: $text-middle;
        font-size: $smaller;
    }

</style>

<table>
    <tr>
        {#each header as headerValue}
            <th class="header">{headerValue}</th>
        {/each}
    </tr>
    {#each data as values}
        <tr>
            {#each values as value}
                <td class="value">{value}</td>
            {/each}
        </tr>
    {/each}
</table>