<script>
    export let url, alt, hasShadow;

    const boxShadow = 'box-shadow: 0 40px 125px -25px rgba(50,50,90,.4), 0 15px 75px -35px rgba(0,0,0,.4);';
    const borderRadius = 'border-radius: 4px';

    const styling = hasShadow && (boxShadow + borderRadius);

    let renderVideo = true;

    $: {
        reMountVideo(url);
    }

    function reMountVideo() {
        renderVideo = false;
        setTimeout(() => renderVideo = true, 0);
    }
</script>


<style lang="scss">
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    width: 100%;
    height: auto;
  }

  .hasShadow {
    margin: 8rem 0;
    padding: 0 5rem;
  }
</style>


<div class:hasShadow>
    {#if renderVideo === true}
        <video style={styling} preload="auto" controls>
            <source src={url} type="video/mp4"/>
        </video>
    {/if}
</div>
