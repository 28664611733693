<script>
    import { fade } from "svelte/transition";
</script>

<style>
    div {
        width: 100%;
        height: 100%;
        display: flex;
    }
</style>

<div transition:fade={{ duration: 400 }}>
    <slot/>
</div>