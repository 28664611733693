<script>
    import Endpoint from './endpoint/endpoint.svelte';

    import { global } from 'store';
    import utils from 'utils';

    let apiReference;

    $: apiReference = utils.sortAPIReference($global.apiReference || []);
</script>


{#each apiReference as endpoint}
    <Endpoint data="{endpoint}" />
{/each}