<script>
    export let type = "POST";
    export let url = "/employee";
    export let active = false;
    export let click;
</script>

<style lang="scss">
    @import "httpVerbs";
</style>


<div class="endpoint" on:click={click}>
    <div class="wrapper">
        <span class="{type}">{type}</span>
    </div>

    <div class:active>{url}</div>
</div>


