<script>
    export let selectedElement;
</script>

<style lang="scss">
    @import "headline";
</style>

<div>
    <h5>Title</h5>
    <input
        bind:value={selectedElement.title}
        placeholder="Title"
    />

    <h5>Subtitle</h5>
    <textarea
        bind:value={selectedElement.subtitle}
        placeholder="Subtitle"
    ></textarea>
</div>