<script>
    export let selectedElement;

    let errorMessage = "";

    // The upload handler when a image is selected
    const imageUploadHandler = (event) => {
        const image = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
            selectedElement.url = reader.result;
            selectedElement.fileName = image.name;
        };

        reader.onerror = (error) => {
            errorMessage = error;
            selectedElement.url = "";
        };
    };
</script>

<style lang="scss">
    @import "image";
</style>

<div>
    <p>{errorMessage}</p>
    <h5>Image</h5>
    <input
        type="file"
        accept="image/png"
        on:change={imageUploadHandler}
    />

    <h5>Description</h5>
    <input
        type="text"
        placeholder="Description"
        bind:value={selectedElement.alt}
    />
</div>
