<script>
    // Object blueprints of types
    import TypeObjectBlueprints from './typeBlueprints';

    // Element Templates
    import HeadlineTemplate from './templates/headline/headline.svelte';
    import SectionTitleTemplate from './templates/sectionTitle/sectionTitle.svelte';
    import TextblockTemplate from './templates/textblock/textblock.svelte';
    import CodeTemplate from './templates/code/code.svelte';
    import InfoBoxTemplate from './templates/infoBox/infoBox.svelte';
    import TemplateTextTemplate from './templates/templateText/templateText.svelte';
    import TableTemplate from './templates/table/table.svelte';
    import ImageTemplate from './templates/image/image.svelte';
    import VideoTemplate from './templates/video/video.svelte';

    export let saveHandler;
    export let closeHandler;
    export let elements = [];
    export let selectedElementIndex;

    // All available types
    const elementTypes = [
        { text: 'Headline', type: 'headline', component: HeadlineTemplate },
        { text: 'Section title', type: 'sectionTitle', component: SectionTitleTemplate },
        { text: 'Text block', type: 'textblock', component: TextblockTemplate },
        { text: 'Template text', type: 'templateText', component: TemplateTextTemplate },
        { text: 'Info box', type: 'infoBox', component: InfoBoxTemplate },
        { text: 'Image', type: 'image', component: ImageTemplate },
        { text: 'Code', type: 'code', component: CodeTemplate },
        { text: 'Table', type: 'table', component: TableTemplate },
        { text: 'Video', type: 'video', component: VideoTemplate }
    ];

    // The object of the selected element
    let selectedElement = elements[selectedElementIndex];

    // The selected element type from the dropdown
    $: selectedElementType = elementTypes.find(x => x.type === selectedElement.type) || {};

    /**
     * When the type is changed (from the dropdown), we overwrite the selectedElement
     * with a blueprint of the new types object
     * (blueprint: the structure of the object of this element with empty values)
     */
    $: typeChangeHandler = ({ target: { options } }) => {
        if (options.selectedIndex === 0) {
            return;
        }

        const selection = elementTypes.find(x => x.type === options[options.selectedIndex].value);

        selectedElement = { ...TypeObjectBlueprints[selection.type] };

        selectedElementType = selection;
    };

</script>

<style lang="scss">
  @import "modal";
</style>

<div class="backdrop">
    <div class="modal">

        <h5>Element type</h5>
        <select on:change={typeChangeHandler}>
            <option value=null selected disabled>Select Type...</option>
            {#each elementTypes as elementType}
                <option
                    selected={selectedElementType.type === elementType.type}
                    value={elementType.type}
                >
                    {elementType.text}
                </option>
            {/each}
        </select>

        {#if selectedElementType.component}
            <svelte:component this={selectedElementType.component} bind:selectedElement/>
            {:else}
            <p>Select an element type</p>
        {/if}

        <div class="buttons">
            <button on:click={() => saveHandler(selectedElement)}>Save</button>
            <button on:click={closeHandler}>Cancel</button>
        </div>
    </div>
</div>