<script>
    import { fly } from "svelte/transition";

    // Custom API UI
    import Endpoint from "./httpVers/httpVerb.svelte";
    import Table from "./table/table.svelte";
    import Code from "./code/code.svelte";

    // Global UI
    import InfoBox from "components/UI/infoBox/infoBox.svelte";

    export let data = {
        name: "",
        endpoints: [],
        caption: "",
        fields: [],
        code: []
    };

    let endpoint;
    let index = 0;

    /**
     * Changes the selected endpoint to
     * the respective details.
     * @param i
     */
    const changeEndpoint = (i) => index = i;

    $: endpoint = data.endpoints[index];

    // Transitions
    const t1 = { x: 70, duration: 200, delay: 150 };
    const t2 = { x: 70, duration: 200 };
</script>


<style lang="scss">
    @import "endpoint";
</style>


<div class="wrapper" id="{data.id}" in:fly={t1} out:fly={t2}>
    <article>
        <h3>{data.name}</h3>

        <hr>

        <h4>Endpoints</h4>

        {#each data.endpoints as ep, i}
            <Endpoint type="{ep.type}" url="{ep.url}" active="{i === index}" click="{() => changeEndpoint(i)}"/>
        {/each}

        <br/>

        <h4>Details</h4>

        <p>{endpoint.caption}</p>

        {#if endpoint.infoBox}
            <InfoBox color={endpoint.infoBox.color} text={endpoint.infoBox.content}/>
        {/if}

        {#if endpoint.fields}
            <h4>Body</h4>
            <Table fields="{endpoint.fields}"/>
        {/if}

        {#if endpoint.params}
            <h4>Query Params</h4>
            <Table tableType="Params" fields="{endpoint.params}" />
        {/if}
    </article>


    <article>
        <hr class="border"/>

        {#each endpoint.code as cb, i}
            <Code title="{cb.title}" code="{JSON.stringify(cb.code, null, 2)}"/>
            <hr class="separator" hidden="{i === endpoint.code.length - 1}"/>
        {/each}
    </article>
</div>