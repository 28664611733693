<script>
    import ImageLoader from "svelte-lazy-image-loader";

    export let url, alt, hasShadow;

    const boxShadow = "box-shadow: 0 40px 125px -25px rgba(50,50,90,.4), 0 15px 75px -35px rgba(0,0,0,.4);";
    const borderRadius = "border-radius: 4px";

    const styling = hasShadow && (boxShadow + borderRadius);
</script>


<style lang="scss">
    div {
        width: 100%;
    }

    .hasShadow {
        margin: 8rem 0;
        padding: 0 5rem;
    }
</style>


<div class:hasShadow>
    <ImageLoader
        url={url}
        alt={alt}
        imageWidth="100%"
        imageHeight="auto"
        placeholderWidth="100%"
        placeholderHeight="40rem"
        styling={styling}
    />
</div>
