<script>
    export let fields;
    export let tableType = '';
</script>

<style lang="scss">
    @import "table";
</style>

<table>

    <tr>
        <th>{tableType || 'Fields'}</th>
        <th>Description</th>
    </tr>

    {#each fields as {name, type, description, required} }
        <tr>
            <td>
                <span>{name}</span>
                <span>{type}</span>
            </td>

            <td>{description}
                <div class:required>REQUIRED</div>
            </td>
        </tr>
    {/each}

</table>