<script>
    import Header from 'components/UI/header/header.svelte';
    import Modal from './modal/modal.svelte';

    import SectionTitle from 'components/UI/sectionTitle/sectionTitle.svelte';
    import TextBlock from 'components/UI/textBlock/textBlock.svelte';
    import InfoBox from 'components/UI/infoBox/infoBox.svelte';
    import Table from 'components/UI/table/table.svelte';
    import ImageLoader from 'components/UI/imageLoader/imageLoader.svelte';
    import TemplateText from 'components/UI/templateText/templateText.svelte';
    import VideoLoader from 'components/UI/videoLoader/videoLoader.svelte';

    // Storage
    import { global } from 'store';

    let modalOpen = false;

    // Index of the element which was selected
    let selectedElement = 0;

    // User in the cancel to revert changes
    let elementObjectCopy;

    // The current editable elements
    let selectedGuide;

    let previewOn = false;

    // The elements which are shown in the edit
    let elements = [];

    // The previously selected guide (is mainly used for to decide if we wanna show the alert window on change)
    // Should show an alert window when user wants to change the dropdown while a guide is already selected
    let prevSelectedGuide;

    // All the guides which are accessible to edit
    let guides = [
        { name: 'Select a guide to edit' }
    ];

    const existingGuides = Object.keys($global.guides) || [];

    // Pushing existing guides into the array
    for (let i = 0; i < existingGuides.length; i++) {
        const guide = $global.guides[existingGuides[i]];

        guides.push({
            value: guide.url,
            name: guide.name
        });
    }

    /**
     * Removes an existing element from the elements list
     * @param index {number} the index where the element should be deleted
     */
    const deleteElement = (index) => {
        const tmp = [...elements];
        tmp.splice(index, 1);
        elements = tmp;
    };

    /**
     * Adds a new element (empty) on the provided index
     * @param index {number} the index where the new element should be added
     */
    const addElement = (index) => {
        const tmp = [...elements];
        tmp.splice(index + 1, 0, '');
        elements = tmp;
    };

    /**
     * When a element is clicked, we open the modal and update the selectedElement (index)
     * @param elementIndex {number} the index of the selected element
     */
    const modalHandler = (elementIndex) => {
        if (previewOn) {
            return;
        }

        modalOpen = !modalOpen;

        // Before we open the modal and make the element mutable, we save it's current state, so for later
        // if the cancel button is clicked, we still have our original element
        elementObjectCopy = JSON.parse(JSON.stringify(elements[elementIndex]));

        if (typeof elementIndex === 'number') {
            selectedElement = elementIndex;
        }
    };

    // Simply closes the modal for editing
    const closeHandler = () => {
        // Revert changes the the original one right before the edit for it was opened
        elements[selectedElement] = elementObjectCopy;

        modalOpen = false;
    };

    /**
     * When the save button in the window is clicked, we receive
     * the new element object and overwrite with it the current one
     * @param updatedElement {object} the element object, key "type" is always inside, the rest depends on element type
     */
    const saveHandler = (updatedElement) => {
        const tmp = [...elements];

        tmp[selectedElement] = updatedElement;

        elements = tmp;

        modalOpen = false;
    };

    /**
     * This function is called, when the dropdown for selecting a guide is changed.
     * It checks, if we have a selectedGuide which we can work with, if not we empty the elements
     * and return. If we got something to work with, we take the value of selectedGuide which is the exact key
     * of the guide in the documentation object (or it should be) and overwrite the current elements with its elements.
     */
    $: guideChangeHandler = () => {
        let changeGuide = true;

        if (prevSelectedGuide) {
            changeGuide = confirm('This will reset all your changes, are you sure?');
        }

        // If the user answers the window confirmation with "cancel", we don't change the guide
        if (!changeGuide) {
            return;
        }

        prevSelectedGuide = selectedGuide;

        if (!selectedGuide) {
            return elements = [];
        }

        if ($global.guides[selectedGuide]) {
            return elements = $global.guides[selectedGuide].elements;
        }
    };

    /**
     * Format the date to a format of DD.MM.YYYY
     * @param date {Date} the date which should be formatted
     */
    const formatDate = (date) => {
        const getMonth = (date.getMonth() + 1);
        const getDay = (date.getUTCDate());
        const day = (getDay < 10) ? '0' + getDay : getDay;
        const month = (getMonth < 10) ? '0' + getMonth : getMonth;
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    /**
     * Downloads the file of the updated guide as JSON
     */
    const exportFile = () => {
        if (!selectedGuide) {
            return;
        }

        const newGuide = { ...$global.guides[selectedGuide] };

        newGuide.lastEdit = formatDate(new Date());
        newGuide.elements = elements;

        const configFile = JSON.stringify(newGuide, null, 2);
        const element = document.createElement('a');

        element.setAttribute('href', `data:application/json;charset=utf-8,` + encodeURIComponent(configFile));
        element.setAttribute('download', selectedGuide);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    /**
     * Adds a new guide to the list, this is only temporary and will be deleted on refresh, since we push it in the variable.
     */
    const addNewGuide = () => {
        // Asking the user for the guide definition
        const guideName = prompt('Please give the new guide a definition', '');

        // Format the name to a object valid key (My new guide -> my-new-guide)
        const guideObjectDefinition = guideName.trim().toLowerCase().replace(/\s/g, '-');

        // Updating the guides dropdown options
        guides = [...guides, { value: guideObjectDefinition, name: guideName }];

        // Generating the new object of the guide for the guides list
        const newGuideObject = {
            name: guideName,
            url: guideObjectDefinition,
            isNew: true,
            lastEdit: formatDate(new Date()),
            elements: []
        };

        // Updating the guides in the storage with the new guide
        global.update(prevStore => ({
            ...prevStore,
            guides: { ...$global.guides, [guideObjectDefinition]: newGuideObject }
        }));

        elements = $global.guides[guideObjectDefinition].elements;

        // Updating the selected Guide
        selectedGuide = guideObjectDefinition;
    };

    /**
     * Helper function, which moves an element from the current index to the new index
     * @param currentIndex {number} the current index of the to be moved element
     * @param newIndex {number} the index where the element should be moved
     * @param element {object} the element object itself
     */
    const moveElementInArray = (currentIndex, newIndex, element) => {
        const newElements = [...elements];

        [newElements[newIndex], newElements[currentIndex]] = [newElements[currentIndex], newElements[newIndex]];

        elements = newElements;
    };

    /**
     * Is called when the arrow up is clicked, which moves the element 1 index higher
     * @param currentIndex {number} the current index of the to be moved element
     * @param element {object} the element object itself
     */
    $: moveElementUp = (currentIndex, element) => {
        if (currentIndex === 0) {
            return;
        }

        return moveElementInArray(currentIndex, currentIndex - 1, element);
    };

    /**
     * Is called when the arrow down is clicked, which moves the element 1 index lower
     * @param currentIndex {number} the current index of the to be moved element
     * @param element {object} the element object itself
     */
    $: moveElementDown = (currentIndex, element) => {
        if (currentIndex === elements.length) {
            return;
        }

        return moveElementInArray(currentIndex, currentIndex + 1, element);
    };

    /**
     * Uploads a JSON file which reads the guide content and recreates it to edit.
     */
    const uploadJSON = () => {
        const input = document.createElement('input');

        input.type = 'file';
        input.accept = '.json';

        input.onchange = (e) => {
            const file = e.target.files[0];

            const fileReader = new FileReader();

            fileReader.onload = (e) => {
                const guideContent = JSON.parse(e.target.result);

                elements = guideContent.elements;
                selectedGuide = guideContent.url;

                guides = [...guides, { value: guideContent.url, name: guideContent.name }];
                global.update(prevStore => ({
                    ...prevStore,
                    guides: { ...$global.guides, [guideContent.url]: guideContent }
                }));
            };

            fileReader.readAsText(file);
        };

        input.click();
    };

</script>

<style lang="scss">
  @import "editor";
</style>

{#if modalOpen}
    <Modal
        saveHandler={saveHandler}
        closeHandler={closeHandler}
        elements={elements}
        selectedElementIndex={selectedElement}
    />
{/if}

<div class="wrapper">

    <div class="toolbar">
        <select bind:value={selectedGuide} on:change={guideChangeHandler}>
            {#each guides as guide}
                <option value={guide.value}>
                    {guide.name}
                </option>
            {/each}
        </select>

        <button on:click={exportFile}>
            <ion-icon name="ios-save"></ion-icon>
        </button>

        <button on:click={addNewGuide}>
            <ion-icon name="ios-add-circle"></ion-icon>
        </button>

        <button on:click={uploadJSON}>Load</button>

        <button on:click={() => previewOn = !previewOn}>Preview {previewOn ? 'ON' : 'OFF'}</button>
    </div>

    <div style={(!selectedGuide || previewOn) && "display: none"} class="add" on:click={() => addElement(-1)}>+</div>

    {#each elements as element, i}
        <div class="element" style={previewOn && "cursor: default; box-shadow: none"}>

            <div style={previewOn && "display: none"} class="arrows">
                <div class="arrowUp" on:click={() => moveElementUp(i, element)}>
                    <ion-icon name="arrow-up"/>
                </div>
                <div class="arrowDown" on:click={() => moveElementDown(i, element)}>
                    <ion-icon name="arrow-down"/>
                </div>
            </div>

            <div style={previewOn && "display: none"} class="delete" on:click={() => deleteElement(i)}></div>

            <div class="content" on:click={() => modalHandler(i)}>
                {#if element.type === "headline"}
                    <Header title={element.title} subtitle={element.subtitle}/>
                {:else if element.type === "sectionTitle"}
                    <SectionTitle title={element.title}/>
                {:else if element.type === "textblock"}
                    <TextBlock text={element.text}/>
                {:else if element.type === "templateText"}
                    <TemplateText text={element.text} replaceKeyValue={element.replaceKeyValue}/>
                {:else if element.type === "infoBox"}
                    <InfoBox color={element.color} text={element.text}/>
                {:else if element.type === "image"}
                    <ImageLoader url={element.url} alt={element.alt} hasShadow/>
                {:else if element.type === "code"}
                    <pre>
                        <code>{element.text}</code>
                    </pre>
                {:else if element.type === "table"}
                    <Table header={element.header} data={element.data}/>
                {:else if element.type === "video"}
                    <VideoLoader url={element.url} alt={element.alt} hasShadow/>
                {:else}
                    <h5 style={previewOn && "display: none"}>Click to edit...</h5>
                {/if}
            </div>

        </div>

        <div style={previewOn && "display: none"} class="add" on:click={() => addElement(i)}>+</div>
    {/each}
</div>