<script>
    export let selectedElement;

    const values = [
        {text: "Cluster", variable: "cluster"},
        {text: "Namespace", variable: "namespace"}
    ];

    const regex = /{{([\w\d])+}}/gi;

    const onTextChange = () => {
        let keysToReplace = selectedElement.text.match(regex) || [];

        // Removing the brackets from the user defined strings in the text
        keysToReplace = keysToReplace.map(x => x.replace("{{", "").replace("}}", ""));

        // Checking if there is an object in replacer which doesn't exist in the text anymore
        selectedElement.replaceKeyValue.forEach((replacer, index) => {
            const replaceKeyStillExists = keysToReplace.some(x => x === replacer.replaceKey);

            // If the key still exists in the text, we skip it and don't change anything
            if (replaceKeyStillExists) {
                return
            }

            // If the replaceKey is not in the text anymore, we remove it from the array
            selectedElement.replaceKeyValue.splice(index, 1);
        });

        // Looping over the results of the regex and checking if it already exists in the replacer, if not we push it in
        (keysToReplace || []).forEach(newReplaceKey => {
            // When the replaceKey is already in the list, skip it
            if (selectedElement.replaceKeyValue.find(x => x.replaceKey === newReplaceKey)) {
                return;
            }

            selectedElement.replaceKeyValue.push({
                replaceKey: newReplaceKey,
                replaceValue: "cluster"
            });
        });
    };

</script>

<style lang="scss">
    @import "templateText";
</style>

<div>
    <h5>Text</h5>
    <textarea
        bind:value={selectedElement.text}
        placeholder={`This is a template text with \{\{dynamicValues\}\}`}
        on:keydown={onTextChange}
    ></textarea>

    <div>
        {#each selectedElement.replaceKeyValue || [] as replacer}
            <div>
                <span>
                    {replacer.replaceKey}
                </span>

                <select bind:value={replacer.replaceValue}>
                    {#each values as value}
                        <option value={value.variable}>
                            {value.text}
                        </option>
                    {/each}
                </select>
            </div>
        {/each}
    </div>

</div>