<script>
    export let selectedElement;
</script>

<style lang="scss">
    @import "sectionTitle";
</style>

<div>
    <h5>Title</h5>
    <input
        bind:value={selectedElement.title}
        placeholder="Section Title"
    />
</div>