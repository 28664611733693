// Searches for the cluster param in the provided URL
const getCluster = (path) => (/cluster=(.[a-z]*)/.exec(path))?.[1];

// Searches for the namespace param in the provided URL
const getNamespace = (path) => (/namespace=(.[\w]*)/.exec(path))?.[1];

// Searches for the access token param in the provided URL
const getAccessToken = (path) => (/at=(.[\w]*)/.exec(path))?.[1];

// Sorts the API reference alphabetically
const sortAPIReference = (apiReference) => apiReference.sort((a, b) => (a.name > b.name) ? 1 : -1);

// Checks if the URL was called with the preview params
const isPreview = (path) => (/mode=(.[a-z]*)/.exec(path))?.[1] === 'preview';


export default { getCluster, getNamespace, getAccessToken, sortAPIReference, isPreview };