<script>
    import { global } from '../../../store';
</script>

<style lang="scss">
    @import "styling/stylesheet";

    .banner {
        position: fixed;
        top: 2rem;
        right: 0;
        left: 0;
        width: 25rem;
        height: 5.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border-radius: 0.7rem;
        background-color: $blue-transparent;
        font-size: $larger;
        font-weight: 600;
        color: $white;
    }
</style>

<div class="banner" hidden="{!$global.isPreview}">Preview Mode</div>