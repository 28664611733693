<script>
    export let selectedElement;

    // All available colors for the info box
    const colors = [
        {text: "Blue", value: "blue"},
        {text: "Red", value: "red"},
        {text: "Green", value: "green"},
        {text: "Orange", value: "orange"},
        {text: "Grey", value: "grey"},
        {text: "Purple", value: "purple"},
        {text: "Yellow", value: "yellow"}
    ];
</script>

<style lang="scss">
    @import "infoBox";
</style>

<div>
    <h5>Color</h5>
    <select bind:value={selectedElement.color} >
        {#each colors as color}
            <option value={color.value}>
                {color.text}
            </option>
        {/each}
    </select>

    <h5>Text</h5>
    <textarea
        bind:value={selectedElement.text}
        placeholder="Text"
    ></textarea>
</div>