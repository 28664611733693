<script>
    // Libs
    import { Route, Router } from 'svelte-routing';

    // Components
    import Menu from './menu/menu.svelte';

    // Store
    import { global } from 'store';

    // Config
    import config from 'config';
    import utils from 'utils';

    // Menus
    let apiMenu;
    let guidesMenu;
    let codeExamples;

    $: apiMenu = utils.sortAPIReference($global.apiReference || []).map(({ name, id }) => ({ label: name, path: '#' + id }));
    $: guidesMenu = Object.entries($global.guides).map((guide) => ({ label: guide[1].name, path: guide[1].url }));
    $: codeExamples = Object.entries($global.codeExamples).map((example) => ({ label: example[1].name, path: example[1].url }));

    const mainMenu = [
        { label: 'API Reference', path: config.slugs.api, icon: 'ios-construct' },
        { label: 'Guides', path: config.slugs.guides + 'enabling-the-rest-api', icon: 'ios-desktop' },
        { label: 'Code Examples', path: config.slugs.codeExamples + 'coming-soon', icon: 'ios-code' }
    ];
</script>

<style lang="scss">
    @import "sidebar";
</style>

<aside>
    <div class="logo"><b><span>jacando</span> X</b> | Docs</div>

    <div class="sidebar">
        <Router>
            <Route path="{config.slugs.api}">
                <Menu data={apiMenu} prefix="{config.slugs.api}" isHashBased />
            </Route>

            <Route path="{config.slugs.guides + ':name'}">
                <Menu data={guidesMenu} prefix="{config.slugs.guides}" />
            </Route>

            <Route path="{config.slugs.codeExamples + ':name'}">
                <Menu data={codeExamples} prefix="{config.slugs.codeExamples}" />
            </Route>

            <Route>
                <Menu data={mainMenu} isMainMenu />
            </Route>
        </Router>
    </div>

    <footer>
        <a href="https://www.jacando.com" target="_blank" rel="noopener noreferrer">
            <ion-icon name="ios-globe"></ion-icon>
            <span>jacando AG</span>
        </a>

        <a href="http://support.jacando.io/" target="_blank" rel="noopener noreferrer">
            <ion-icon name="ios-contacts"></ion-icon>
            <span>Help Center</span>
        </a>

        <a href="https://status.jacando.io" target="_blank" rel="noopener noreferrer">
            <ion-icon name="ios-power"></ion-icon>
            <span>Status</span>
        </a>
    </footer>
</aside>