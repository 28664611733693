<script>
    import InfoBox from "components/UI/infoBox/infoBox.svelte";

    export let password;
    export let errorMessage;
    export let isLoading = false;

</script>


<style lang="scss">
    @import "login";
</style>


<main>

    <div class="notification" hidden="{!errorMessage}">{errorMessage}</div>

    <div class="box">
        <div class="logo"><b><span>jacando</span> X</b> | Docs</div>

        <InfoBox
                color="blue"
                text="If you have already purchased jacando X, please open the Docs through the settings page."
        />

        <form on:submit|preventDefault>
            <input
                    aria-label="password"
                    id="password"
                    type="password"
                    autocomplete="off"
                    bind:value={password}
                    placeholder="Access Token"
                    required
            />

            <button type="submit" disabled="{isLoading}">
                <div class="loader" hidden="{!isLoading}"></div>
                {(isLoading) ? "Loading" : "Submit"}
            </button>
        </form>

        <div class="row">
            <ion-icon name="ios-help-circle-outline"></ion-icon>
            <span>Need access? Contact our support.</span>
        </div>
    </div>

</main>