<script>
    export let text = "";
</script>

<style lang="scss">
    @import "styling/stylesheet";

    p {
        margin: 0 0 2.5rem 0;
        line-height: 2.8rem;
        text-align: justify;
        font-weight: 500;
        color: $text-middle;
    }
</style>

<p>{text}</p>