<script>
    // String   - The text in the button
    export let label = "Button";

    // Function - The onclick callback
    export let onClick = () => {
    };

    // Boolean  - If the button was clicked (used for the copy button in rest api)
    export let wasClicked = false;
</script>

<style lang="scss">
    @import "styling/stylesheet";

    button {
        min-width: 8rem;
        height: 2.5rem;
        border: none;
        border-radius: 2px;
        background-color: $code-yellow;
        font-weight: bold;
        color: $text-black;
        cursor: pointer;
    }

    // Used for the copy button
    .wasClicked {
        background-color: $green;
    }
</style>

<button on:click="{onClick}" class:wasClicked>{label}</button>