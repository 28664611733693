<script>
    import { fly } from "svelte/transition";
    import { link } from "svelte-routing";

    import Hero from "./hero/hero.svelte";
    import LatestGuides from "./latestGuides/latestGuides.svelte"

    import { global } from "store";
    import configs from "config";

    let guides;

    $: guides = $global.guides;

    // Sort the guides depending on last edit
    let latestGuides = Object.values($global.guides || {}).sort((currentGuide, nextGuide) => {
        const splitCurrentDate = currentGuide.lastEdit.split(".");
        const splitNextDate = nextGuide.lastEdit.split(".");

        const currentDate = splitCurrentDate[2] + "." + splitCurrentDate[1] + "." + splitCurrentDate[0];
        const nextDate = splitNextDate[2] + "." + splitNextDate[1] + "." + splitNextDate[0];

        return new Date(nextDate) - new Date(currentDate);
    });

    // Safety check, if it is undefined we overwrite it as empty array
    if (!latestGuides) {
        latestGuides = [];
    }

    // We are taking the first 3 from the list
    latestGuides = latestGuides.slice(0, 3);

    // Transitions
    const t1 = { x: 70, duration: 200, delay: 100 };
    const t2 = { x: 70, duration: 200 };

</script>

<style lang="scss">
    @import "home";
</style>

<div class="wrapper" in:fly={t1} out:fly={t2}>
    <h2>Get Started</h2>

    <h5>
        Learn how to set up and integrate your jacando X into your existing infrastructure.
        This documentation contains the full REST API reference and step by step guides to get you started.
    </h5>

    <h4>Categories</h4>

    <div class="categories">
        <a href="{configs.slugs.api}" class="card" use:link>
            <ion-icon name="ios-construct"></ion-icon>
            <span>REST API Reference</span>
        </a>

        <a href="{configs.slugs.guides + 'enabling-the-rest-api'}" class="card" use:link>
            <ion-icon name="ios-desktop"></ion-icon>
            <span>Guides</span>
        </a>

        <a href="{configs.slugs.codeExamples + 'coming-soon'}" class="card" use:link>
            <ion-icon name="ios-code"></ion-icon>
            <span>Code Examples</span>
        </a>
    </div>

    <h4>Guides</h4>

    <Hero/>

    <h4>Latest</h4>

    <LatestGuides latestGuides={latestGuides}/>
</div>