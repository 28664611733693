<script>
    export let selectedElement;

    let errorMessage = "";

    // The upload handler when a video is selected
    const videoUploadHandler = (event) => {
        const video = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(video);

        reader.onload = () => {
            selectedElement.url = reader.result;
            selectedElement.fileName = video.name;
        };

        reader.onerror = (error) => {
            errorMessage = error;
            selectedElement.url = "";
        };
    };
</script>

<style lang="scss">
  @import "video";
</style>

<div>
    <p>{errorMessage}</p>
    <h5>Video</h5>
    <input
        type="file"
        accept="video/mp4"
        on:change={videoUploadHandler}
    />

    <h5>Description</h5>
    <input
        type="text"
        placeholder="Description"
        bind:value={selectedElement.alt}
    />
</div>
