<script>
    export let selectedElement;
</script>

<style lang="scss">
    @import "textblock";
</style>

<div>
    <h5>Text</h5>
    <textarea
        bind:value={selectedElement.text}
        placeholder="Text"
    ></textarea>
</div>