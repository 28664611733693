<script>
    import TextBlock from "components/UI/textBlock/textBlock.svelte";

    import { global } from "store";

    export let text = "";
    export let replaceKeyValue = [];

    const regex = /{{([\w\d])+}}/gi;

    $: dynamicFields = text.match(regex) || [];

    $: displayText = text;

    // Dynamic values (if new added, don't forget to adjust it in the editor)
    const replaceValues = {
        cluster: ($global.cluster ? $global.cluster : "<cluster>"),
        namespace: ($global.namespace ? $global.namespace : "<namespace>")
    };

    $: displayText = replaceKeyValue.reduce((currentValue, currReplacer) => {
        const fieldToReplace = dynamicFields.find(x => x.includes(currReplacer.replaceKey));

        if (!fieldToReplace) {
            return;
        }

        // We need the to be replaced value as regex since we want it to replace globally
        // .replace by default only replaces the first found value and then stops
        const replaceRegex = new RegExp(fieldToReplace, "g");

        // Replaces the value with the brackets in the text with the selected value
        // and returns the results of it (important, we don't manipulate the original value here)
        return currentValue.replace(replaceRegex, replaceValues[currReplacer.replaceValue]);
    }, text);

</script>

<TextBlock text={displayText}/>