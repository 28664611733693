<script>
    export let title = "";
    export let subtitle = "";
</script>

<style lang="scss">
    @import "styling/stylesheet.scss";

    h2 {
        margin-bottom: 1.5rem;
    }

    h5 {
        margin-bottom: 7rem;
    }
</style>

<h2>{title}</h2>
<h5>{subtitle}</h5>